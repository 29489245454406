import React from 'react';
import { graphql } from 'gatsby';

export const query = graphql`
  query($slug: String!) {
    glossaryTerm(slug: { eq: $slug }) {
      also_known_as
      category
      definition
      misconception
      purpose
      read_more
      related
      term
      type
    }
  }
`;

export type TermTemplateProps = {
  data: {
    glossaryTerm: GatsbyTypes.GlossaryTerm;
  };
};

const TermTemplate: React.FC<TermTemplateProps> = ({ data }) => {
  const entry = data.glossaryTerm;
  return (
    <div>
      <h1>{entry.term}</h1>
      <pre>{JSON.stringify(entry, undefined, 2)}</pre>
    </div>
  );
};

export default TermTemplate;
